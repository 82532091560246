import React from 'react';
import { graphql } from 'gatsby';
import { getImageUrl } from 'takeshape-routing';

import Layout from '../layouts/default';

export const Page = ({ page }) => (
  <>
    <article className="pageMain">
      <header className="pageHeader">
        {page.mastheadImage ? (
          <img
            className="cafe__cover-image"
            alt={page.pageTitle}
            src={getImageUrl(page.mastheadImage.path, {
              h: 600,
              w: 1200,
              fit: 'cover',
            })}
          />
        ) : (
          ''
        )}
        {page.pageTitle && (
          <h1>{page.pageTitle}</h1>
        )}
      </header>
      <section className="pageCopy rte">
        {page.bodyHtml && (
          <section dangerouslySetInnerHTML={{ __html: page.bodyHtml }} />
        )}
      </section>
    </article>
  </>
);

export default ({ data }) => (
  <Layout>
    <Page page={data.takeshape.page} />
  </Layout>
);

export const query = graphql`
  query($pageId: ID!) {
    takeshape {
      page: getPage(_id: $pageId) {
        pageTitle
        bodyHtml
        mastheadImage {
          path
        }
      }
    }
  }
`;
